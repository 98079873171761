<template>
  <b-card>
    <template #header>
      <card-header
        :title="activeSitesLabel"
      />
    </template>
    <b-row>
      <b-col cols="12">
        <p class="form-label">
          <strong>{{legalAddressLabel}}</strong>
          <br>
          {{legalAddressDataRow1}}
          <br>
          {{legalAddressDataRow2}}
        </p>
      </b-col>
<!--      <b-col cols="6">-->
<!--        <p class="form-label">-->
<!--          <strong>{{operationalAddressLabel}}</strong>-->
<!--          <br>-->
<!--          {{operationalAddressDataRow1}}-->
<!--          <br>-->
<!--          {{operationalAddressDataRow2}}-->
<!--        </p>-->
<!--      </b-col>-->
    </b-row>
  </b-card>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'ProfileTabsDataSites',
  components: { CardHeader },
  props: {
    data: Object,
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    activeSitesLabel() {
      return 'Sedi attive';
    },
    activeSitesSubLabel() {
      const a = this.data?.addressList?.length;
      const subs = a || this.notAvailableMsg;
      return `N. uffici sedi secondarie: ${subs}`;
    },
    legalAddressLabel() {
      return 'Sede Legale:';
    },
    legalAddressDataRow1() {
      if (isNotEmpty(this.data?.legalAddress)) {
        console.log('this.data.legalAddress', this.data.legalAddress);
        const a = this.data.legalAddress.streetName;
        const b = this.data.legalAddress.streetNumber;
        if (isNotEmpty(b)) {
          return `${a}, ${b}`;
        }
        return a;
      }
      return this.notAvailableMsg;
    },
    legalAddressDataRow2() {
      if (isNotEmpty(this.data?.legalAddress)) {
        const a = this.data.legalAddress.city;
        const b = this.data.legalAddress.zip || '';
        const prov = this.data.legalAddress.province;
        const c = prov ? `(${prov})` : '';
        if (isNotEmpty(b) || isNotEmpty(c)) {
          return `${a} - ${b} ${c}`;
        }
        return a;
      }
      return this.notAvailableMsg;
    },
    // legalAddressPhone() {
    //   if (this.data?.profile?.contactDetail?.phoneNumber1) {
    //     const a = this.data?.profile.contactDetail.phoneNumber1;
    //     return `TEL: ${a}`;
    //   }
    //   return `TEL: ${this.notAvailableMsg}`;
    // },
    // legalAddressEmail() {
    //   // non so quale sia il dato --guy
    //   return `EMAIL: ${this.notAvailableMsg}`;
    // },
    // legalAddressWeb() {
    //   if (this.data?.profile?.contactDetail?.webSite) {
    //     const a = this.data?.profile.contactDetail.webSite;
    //     return `WEB: ${a}`;
    //   }
    //   return this.notAvailableMsg;
    // },
    operationalAddressLabel() {
      return 'Sede Operativa:';
    },
    operationalAddressDataRow1() {
      if (isNotEmpty(this.data?.addressList)) {
        console.log('this.data.addressList', this.data.addressList);
        const a = this.data.addressList[0].streetName;
        const b = this.data.addressList[0].streetNumber;
        if (isNotEmpty(b)) {
          return `${a}, ${b}`;
        }
        return a;
      }
      return this.notAvailableMsg;
    },
    operationalAddressDataRow2() {
      if (isNotEmpty(this.data?.addressList)) {
        const a = this.data.addressList[0].city;
        const b = this.data.addressList[0].zip || '';
        const prov = this.data.addressList[0].province;
        const c = prov ? `(${prov})` : '';
        if (isNotEmpty(b) || isNotEmpty(c)) {
          return `${a} - ${b} ${c}`;
        }
        return a;
      }
      return this.notAvailableMsg;
    },
    // operationalAddressDataRow3() {
    //   if (this.data?.profile?.contactDetail?.operationalAddress) {
    //     return this.data?.profile.contactDetail.operationalAddress[2];
    //   }
    //   return this.notAvailableMsg;
    // },
    // operationalAddressPhone() {
    //   if (this.data?.profile?.contactDetail?.phoneNumber2) {
    //     const a = this.data?.profile.contactDetail.phoneNumber2;
    //     return `TEL: ${a}`;
    //   }
    //   return this.notAvailableMsg;
    // },
  },
  methods: {},
};
</script>

<style scoped>

</style>
